<template>
    <v-container fluid class="mb-12">
        <v-row class="mb-2">
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
            <v-col cols="12">
                <v-card outlined class="p-0">
                    <v-container fluid class="p-3">
                        <v-row>
                            <!-- <v-col class="col-6" sm="6" md="2" lg="2">
                                <p class="blue-lcd mb-1">MILL ID <span class="red--text"><strong>* </strong></span></p>
                                <v-autocomplete
                                    prepend-inner-icon="mdi-fireplace-off"
                                    dense
                                    solo
                                    clearable
                                    v-model="mill"
                                    :items="mills"
                                    item-value="mill_id"
                                    item-text="mill_name"
                                    label="MILL ID"
                                    class="ma-0 pa-0 "
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col> -->
                            <v-col class="col-12 pt-1 pb-1" sm="6" md="2">
                                <p class="blue-lcd mb-1">Order ID</p>
                                <v-autocomplete
                                    solo
                                    dense
                                    clearable
                                    v-model="order_id"
                                    :items="orders"
                                    :search-input.sync="searchOrder"
                                    cache-items
                                    hide-no-data
                                    hide-details
                                    allow-overflow
                                    item-value="customerorderno"
                                    item-text="customerorderno"
                                    :loading="loadingOrder"
                                    class="ma-0 pa-0 "
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-12 pt-1 pb-1" sm="6" md="2">
                                <p class="blue-lcd mb-1">Coil ID</p>
                                <v-autocomplete
                                    solo
                                    dense
                                    clearable
                                    v-model="coil"
                                    :items="coils"
                                    :search-input.sync="searchCoil"
                                    cache-items
                                    hide-no-data
                                    hide-details
                                    allow-overflow
                                    item-value="coil_id"
                                    item-text="coil_id"
                                    class="ma-0 pa-0 "
                                    :loading="loadingCoil"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-12 pt-1 pb-1" sm="6" md="2">
                                <p class="blue-lcd mb-1">PO ID</p>
                                <v-autocomplete
                                    solo
                                    dense
                                    clearable
                                    v-model="po_id"
                                    :items="po_ids"
                                    :search-input.sync="searchPO"
                                    cache-items
                                    hide-no-data
                                    hide-details
                                    allow-overflow
                                    item-value="po_id"
                                    item-text="po_id"
                                    class="ma-0 pa-0 "
                                    :loading="loadingPO"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-12 pt-1 pb-1" sm="6" md="2">
                                <v-btn color="primary" class="pb-0 mr-3 mt-6" @click="getCoilTransaction">Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mb-12">
            <v-col class="col-12" sm="12" md="5">
                <v-card outlined class="p-0">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="mr-3">mdi-table</v-icon> List Coil </h6>
                            </v-col>
                            <v-col cols="12">
                                <v-data-table
                                    dense
                                    :headers="headers"
                                    :items="coil_transactions"
                                    class="elevation-1"
                                    :items-per-page="30"
                                    :footer-props="{
                                        'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                    }"
                                    :loading="$store.state.overlay"
                                    height="425"
                                    fixed-header
                                    :divider="true"
                                    :light="true"
                                    :search="searchCoilTrans" 
                                    :item-class="tr_datatable"
                                    @click:row="showDetailCoil"
                                >    
                                    <template v-slot:top>
                                        <v-toolbar flat color="white" class="mt-2 mb-2">
                                            <div class="d-flex w-100">
                                                <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchCoilTrans" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                <v-spacer></v-spacer>
                                            </div>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.width`]="{ item }">
                                        {{ $store.getters.convertToCurrencyNoFrac(item.width) }}
                                    </template>
                                    <template v-slot:[`item.thick`]="{ item }">
                                        {{ $store.getters.convertToCurrencyUs(item.thick) }}
                                    </template> 
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-col class="col-12" sm="12" md="7">
                <v-card outlined class="p-0">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="mr-3">mdi-table</v-icon> List Orders </h6>
                            </v-col>
                            <v-col cols="12">
                                <v-data-table
                                    dense
                                    :headers="header_orders"
                                    :items="order_transactions"
                                    class="elevation-1"
                                    :items-per-page="30"
                                    :footer-props="{
                                        'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                    }"
                                    :loading="loadingOrderTrans"
                                    height="425"
                                    fixed-header
                                    :divider="true"
                                    :light="true"
                                    :search="searchOrderTrans" 
                                    :item-class="tr_datatable_order"
                                    @click:row="showDetailOrder"
                                >    
                                    <template v-slot:top>
                                        <v-toolbar flat color="white" class="mt-2 mb-2">
                                            <div class="d-flex w-100">
                                                <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchOrderTrans" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                <v-spacer></v-spacer>
                                            </div>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.dt_trans`]="{ item }">
                                        {{ (new Date(new Date(item.dt_trans) - (new Date()).getTimezoneOffset() *
                                            60000)).toISOString().substr(0, 10) }}
                                    </template>
                                    <template v-slot:[`item.panjang`]="{ item }">
                                        {{ $store.getters.convertToCurrencyNoFrac(item.panjang) }}
                                    </template>
                                    <template v-slot:[`item.shift`]="{ item }">
                                        {{ $store.getters.convertToCurrencyNoFrac(item.shift) }}
                                    </template>
                                    <template v-slot:[`item.qty_prod`]="{ item }">
                                        {{ $store.getters.convertToCurrencyUs(item.qty_prod) }}
                                    </template> 
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-col class="col-12" sm="12" md="12">
                <v-dialog
                v-model="dialog"
                persistent
                min-width="800"
                max-width="800"
                >
                    <v-card height="600" style="overflow: auto;">
                        <v-card-title class="text-center mb-0 pb-0">
                            <v-row>
                                <v-col cols="12">
                                    <div class="d-flex">
                                        <div class="ml-0">
                                        Delivery
                                        </div>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            class="mx-2"
                                            fab
                                            x-small
                                            color="#e83e8c;"
                                            @click="[dialog = false]"
                                        >
                                            <v-icon dark>
                                                mdi-close
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-divider></v-divider>
                            </v-row>
                        </v-card-title>
                        <v-card-text class="ma-0 pt-0 ">
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="mr-3">mdi-table</v-icon> List Delivery </h6>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-data-table
                                            dense
                                            :headers="header_deliveries"
                                            :items="deliveries"
                                            class="elevation-1"
                                            :items-per-page="30"
                                            :footer-props="{
                                                'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                            }"
                                            :loading="loadingOrderTrans"
                                            height="425"
                                            fixed-header
                                            :divider="true"
                                            :light="true"
                                            :search="searchDeliveryTrans" 
                                        >    
                                            <template v-slot:top>
                                                <v-toolbar flat color="white" class="mt-2 mb-2">
                                                    <div class="d-flex w-100">
                                                        <v-spacer></v-spacer>
                                                        <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchDeliveryTrans" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                    </div>
                                                </v-toolbar>
                                            </template>
                                            <template v-slot:[`item.ship_date`]="{ item }">
                                                {{ (new Date(new Date(item.ship_date) - (new Date()).getTimezoneOffset() *
                                                    60000)).toISOString().substr(0, 10) }}
                                            </template>
                                            <template v-slot:[`item.length_ship`]="{ item }">
                                                {{ $store.getters.convertToCurrencyNoFrac(item.length_ship) }}
                                            </template>
                                            <template v-slot:[`item.qty_ship`]="{ item }">
                                                {{ $store.getters.convertToCurrencyNoFrac(item.qty_ship) }}
                                            </template>
                                            <template v-slot:[`item.item_num`]="{ item }">
                                                {{ $store.getters.convertToCurrencyUs(item.item_num) }}
                                            </template> 
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'KKA',
                disabled: false,
                href: '/admin/kka',
                },
                {
                text: 'KKA Production',
                disabled: false,
                href: '/admin/kka/production',
                },
                {
                text: 'Coil Genealogy',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            mills: [],
            mill: '',
            coils: [],
            coil: '',
            po_id: '',
            po_ids:[],
            orders: [],
            order_id: '',
            loadingOrder: false,
            loadingCoil: false,
            loadingPO: false,
            searchOrder: null,
            searchCoil: null,
            searchPO: null,
            headers: [
                {
                    text: 'Coil ID',
                    value: 'coil_id',
                    width:150, align: 'left'
                },
                { text: 'Descr', value: 'coil_desc', width:200, align: 'left' },
                { text: 'Grade', value: 'grade_id', width:100, align: 'left' },
                { text: 'Brand ID', value: 'brand_id', width:100, align: 'left' },
                { text: 'AZ', value: 'coating_mass', width:100, align: 'left' },
                { text: 'Quality', value: 'quality_id', width:100, align: 'left' },
                { text: 'Thick', value: 'thick', width:100, align: 'left' },
                { text: 'Width', value: 'width', width:100, align: 'left' },
            ],
            coil_transactions: [],
            searchCoilTrans: '',
            detailCoil: null,
            header_orders: [
                { text: 'Mill ID', value: 'mill_id', width:100, align: 'left' },
                {
                    text: 'DT Trans',
                    value: 'dt_trans',
                    width:150, align: 'left'
                },
                {
                    text: 'Order ID',
                    value: 'customerorderno',
                    width:150, align: 'left'
                },
                { text: 'Num', value: 'item_num', width:100, align: 'left' },
                { text: 'BarangJadiId', value: 'BarangJadiId', width:120, align: 'left' },
                { text: 'Nama Barang', value: 'NamaBarang', width:200, align: 'left' },
                { text: 'Panjang', value: 'panjang', width:100, align: 'right' },
                { text: 'Coil ID', value: 'coil_id', width:100, align: 'left' },
                { text: 'PO ID', value: 'po_id', width:200, align: 'left' },
                { text: 'PO Item', value: 'po_item', width:100, align: 'left' },
                { text: 'Shift', value: 'shift', width:100, align: 'left' },
                { text: 'Operator', value: 'operator', width:100, align: 'left' },
                { text: 'Qty Prod', value: 'qty_prod', width:100, align: 'right' },
            ],
            order_transactions: [],
            loadingOrderTrans: false,
            searchOrderTrans: '',
            detailOrder: null,
            dialog: false,
            header_deliveries: [
                {
                    text: 'Ship DT',
                    value: 'ship_date',
                    width:150, align: 'left'
                },
                {
                    text: 'Order ID',
                    value: 'order_id',
                    width:150, align: 'left'
                },
                {
                    text: 'Deliv ID',
                    value: 'deliv_id',
                    width:150, align: 'left'
                },
                { text: 'Penerima', value: 'receiver', width:100, align: 'left' },
                { text: 'Dikirim ke', value: 'shipto', width:100, align: 'left' },
                { text: 'No Kendaraan', value: 'vehicle_id', width:100, align: 'left' },
                { text: 'Driver', value: 'driver_id', width:100, align: 'left' },
                { text: 'Num', value: 'item_num', width:100, align: 'left' },
                { text: 'Nama Barang', value: 'descr', width:200, align: 'left' },
                { text: 'WH ID', value: 'wh_id', width:100, align: 'left' },
                { text: 'BIN ID', value: 'bin_id', width:100, align: 'left' },
                { text: 'Length Ship', value: 'length_ship', width:100, align: 'right' },
                { text: 'Qty Ship', value: 'qty_ship', width:100, align: 'right' },
                { text: 'Status', value: 'stat', width:100, align: 'left' },
                { text: 'Remark', value: 'remark', width:150, align: 'left' },
            ],
            deliveries: [],
            searchDeliveryTrans: '',
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.getMills()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        tr_datatable(item) {
            var rowClass = 'tr_datatable'
            if (this.detailCoil) {
                if(this.detailCoil.coil_id === item.coil_id){
                    rowClass = 'tr_choice'
                } else {
                    rowClass = 'tr_datatable'
                }
            }
            return rowClass;
        },
        tr_datatable_order(item){
            var rowClass = 'tr_datatable'
            if (this.detailOrder) {
                if(this.detailOrder.customerorderno === item.customerorderno && this.detailOrder.item_num === item.item_num && this.detailOrder.po_id === item.po_id && this.detailOrder.po_item === item.po_item && this.detailOrder.qty_prod === item.qty_prod){
                    rowClass = 'tr_choice'
                } else {
                    rowClass = 'tr_datatable'
                }
            }
            return rowClass;
        },
        async getMills(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/mill?mill_id=${this.mill ? this.mill : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.mills = res.data.data
            });
        },
        async getOrder(id){
            var reqBody = {
                'customerorderno': id
            }
            this.loadingOrder = true
            const respData = await backendApi.fetchCore(`/api/v3/kka/master/customerorderno`, reqBody, false, false, false)
            this.orders = []
            if (respData.status === 200) {
                console.log(respData.data);
                
                this.loadingOrder = false
                this.orders = respData.data
            }
        },
        async getCoil(id){
            var reqBody = {
                'coil_id': id,
                'order_id': this.order_id ? this.order_id : ''
            }
            this.loadingCoil = true

            const respData = await backendApi.fetchCore(`/api/v3/kka/master/coil_id`, reqBody, false, false, false)
            this.coils = []
            if (respData.status === 200) {
                this.loadingCoil = false
                this.coils = respData.data
            }
        },
        async getPO(id){
            var reqBody = {
                'po_id': id,
                'coil_id': this.coil ? this.coil : ''
            }
            this.loadingPO = true

            const respData = await backendApi.fetchCore(`/api/v3/kka/master/po_id`, reqBody, false, false, false)
            this.po_ids = []
            if (respData.status === 200) {
                this.loadingPO = false
                this.po_ids = respData.data
            }
        },
        async getCoilTransaction(){
            var reqBody = {
                'mill_id': this.mill ? this.mill : '',
                'order_id': this.order_id ? this.order_id : '',
                'coil_id': this.coil ? this.coil : '',
                'po_id': this.po_id ? this.po_id : '',
            }

            this.$store.dispatch('setOverlay', true)

            if ((this.order_id === '' || this.order_id === null) && (this.coil === '' || this.coil === null) && (this.po_id === '' || this.po_id === null)) {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please fill your fields',
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)

                return false
            }
            const respData = await backendApi.fetchCore(`/api/v3/kka/coil_genealogy/getCoil`, reqBody, false, false, false)
            this.coil_transactions = []
            this.order_transactions = []
            if (respData.status === 200) {
                this.$store.dispatch('setOverlay', false)
                this.coil_transactions = respData.data.data
            }
        },
        async showDetailCoil(row){
            this.detailCoil = row
            await this.getOrderTransaction()
        },
        async getOrderTransaction(){
            var reqBody = {
                'mill_id': this.mill ? this.mill : '',
                'order_id': this.order_id ? this.order_id : '',
                'coil_id': this.detailCoil.coil_id,
                'po_id': this.po_id ? this.po_id : '',
            }

            this.loadingOrderTrans = true

            const respData = await backendApi.fetchCore(`/api/v3/kka/coil_genealogy/coil_genealogy`, reqBody, false, false, false)

            this.order_transactions = []
            if (respData.status === 200) {
                this.loadingOrderTrans = false
                this.order_transactions = respData.data.data
            }
        },
        async showDetailOrder(row){
            console.log(row);
            this.detailOrder = row
            await this.getDeliveryTransaction()
        },
        async getDeliveryTransaction(){
            var reqBody = {
                'mill_id': this.detailOrder.mill_id ? this.detailOrder.mill_id : '',
                'order_id': this.detailOrder.customerorderno ? this.detailOrder.customerorderno : '',
                'item_num': this.detailOrder.item_num
            }
            this.$store.dispatch('setOverlay', true)
            const respData = await backendApi.fetchCore(`/api/v3/kka/coil_genealogy/getDelivery`, reqBody, false, false, false)
            if (respData.status === 200) {
                this.$store.dispatch('setOverlay', false)
                this.deliveries = respData.data.data
                this.dialog = true
            }
        },
    },
    watch: {
        searchOrder (val) {
            val && val !== this.order_id && this.getOrder(val)
        },
        searchCoil (val) {
            val && val !== this.coil && this.getCoil(val)
        },
        searchPO (val) {
            val && val !== this.po_id && this.getPO(val)
        },
    }
}
</script>